@mixin transition($item: all, $duration: 250ms, $timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275)) {
  -webkit-transition: $item $duration $timing-function;
  -moz-transition: $item $duration $timing-function;
  -ms-transition: $item $duration $timing-function;
  -o-transition: $item $duration $timing-function;
  transition: $item $duration $timing-function;
}

@mixin click($scale: 0.95) {
  transform: scale($scale);

  @include transition(transform, 250ms);
}

@mixin highlightHover($border-color: #00aeef) {
  border-color: $border-color;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

a, a:link {
  color: unset;
  text-decoration: none !important;
}


.clickable-element {
  &:active {
    @include click(0.9);
  }
}

.highlight-hover {
  &:hover {
    @include highlightHover(#cccccc);
  }
}

.animate-in {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  animation: come-in 300ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

input {
  border-radius: 4px;
  border: solid 1px #ccc;
}

.table {
  table-layout: fixed;
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;

  thead {
    background: #efedeb;
    font-weight: 400;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    color: #190b05;
  }

  th,
  td {
    padding: 0.35rem 0.5rem;
    font-size: 0.8rem;
    text-align: left;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }

  th {
    background: #eee;
    color:#000;
  }

  td {
    font-size: 0.8rem;
  }

  tbody {
    tr {
      cursor: pointer;

      &:hover {
        background: #ccc;
      }
    }
  }

  .active-item {
    background: #ccc;
  }
}

@keyframes come-in {
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}
