.priority-button{
  font-size: 1em;
  margin: 0.25em;
  padding: 0.25em 1em;
  border-radius: 3px;
  background: #fdd06e;
  border: 1px solid #cad06e;
  color: black;
  &.selected {
    background: #efa500;
    border: 1px solid #efa500;
    color: #ffffff;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.75);
  }
}
