.input[type=text] {
  height: 40px;
  width: 100%;
  font-size: 16px;
}

.comments-list{
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 0.9rem;

  @media only screen and (max-width: 1280px) {
    font-size: 0.8rem;
  }

  .field-wrp{
    padding: 0 0 5px;
    display: flex;
    justify-content: start;
    align-content: center;
    
    .date, .asignee {
      font-weight: 500;
    }
    label{
      margin-right: 2px;
      vertical-align: middle;
    }
    span{
      margin: 0 10px 0 0;
    }
  }
  .date-field-wrp{
    justify-content: space-between;
  }
  li {
    padding: 10px 0;
    p {
      padding: 5px;
      margin: 0;
      background: #f6f6f6;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }
}
