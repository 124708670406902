.alert-details-wrapper {
    flex: 0 0 25%;
    max-width: 25%;;
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 13px 4px #ddd;
    font-size: 0.9rem;
    position: relative;

    @media only screen and (max-width: 1280px) {
       font-size: 0.8rem;
    }
    .workflow-buttons-wrapper {
        margin: 0;
        height: 35px;
    }

    .workflow-content-wrapper {
        height: calc(100% - 30px);
    }
}

.complete-btn{
    border-radius: 3px;
    border: 1px solid #4cb944;
    background: #4cb944;
    color: #fff;
}

.exclude-btn{
    border-radius: 3px;
    border: 1px solid #ef5656;
    background: #ef5656;
    color: #fff;
}

.in-progress-btn{
    border-radius: 3px;
    border: 1px solid #f1be39;
    background: #f1be39;
    color: #fff;
}

.reopen-btn{
    border-radius: 3px;
    border: 1px solid #a795c8;
    background: #a795c8;
    color: #fff;
}
