.details-table {
  width: 100%;
  padding-right: 15px;
}

.details-row {
  display: flex;
  padding: 5px 0 0;
}

.details-key-col-assignee {
  width: 45%;
  padding-top: 10px;
  font-weight: 500;
}

.details-key-col {
  width: 45%;
  font-weight: 500;
}

.details-val-col {
  width: 55%;
}

.limit-col {
  width: 3%;
}

.value-col {
  width: 3%;
}

a:link {
  text-decoration: underline;
}
