.details-table {
  width: 100%;
  padding-right: 15px;
}

.details-row {
  display: flex;
  padding: 5px 0 0;
}

.details-key-col-assignee {
  width: 45%;
  padding-top: 10px;
}

.details-key-col {
  width: 45%;
  font-weight: 500;
}

.details-val-col {
  width: 55%;
}

.limit-col {
  width: 3%;
}

.value-col {
  width: 3%;
}

a:link {
  text-decoration: underline;
}

.fields-wrp{
  display:flex;
  justify-content: space-between;
}

.fields-wrp-label{
  flex: 0 0 40%;
  margin-top: 15px;
}

.fields-wrp-input{
  flex: 0 0 60%;
  margin-top: 15px;
}

.radio {
  //flex: 0 0 40%;
  //max-width: 40%;
  //justify-content: flex-start;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  //align-items: center;
}
