.details-table {
  width: 100%;
  padding-right: 15px;
}

.details-row {
  display: flex;
  padding: 5px 0 0;
}

.details-key-col-assignee {
  width: 45%;
  padding-top: 10px;
}

.details-key-col {
  width: 45%;
}

.limit-col {
  width: 3%;
}

.value-col {
  width: 3%;
}

@media only screen and (max-width: 1280px) {
  .limit-col, .value-col, .severity-col, .priority-col {
    display: none;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0.2em;
  box-sizing: border-box;
  max-width: 100%
}

.blank_row
{
  height: 20px;
  background-color: #FFFFFF;
}

.savings-status {
  margin: 0.5rem 0 1rem;
  font-weight: 500;
}

.savings-grid{
  display: grid;
  grid-template-columns: 40% 30% 30%;
  grid-gap: 10px;
  width: calc(100% - 30px);
  
  input{
    border-radius: 4px;
    border: solid 1px #ccc;
    width: 80%;
    padding: 5px;
    text-align: right;
    background: #f8f8f8;
    outline: none;  
  }
}