
.more-info-tabs-wrapper{
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.more-info-tabs {
  display:flex;
  height: 50px;
  button {
    font-size: 0.9rem;
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0;
    cursor: pointer;
    &:after {
      border-bottom: 3px solid #00aeef!important;
    }
  }
}

.tab-content {
  height: calc(100% - 45px);
}

.input[type=text] {
  height: 40px;
  width: 100%;
  font-size: 16px;
}

.primary-btn {
  background: #12427d
}

.secondary-btn {
  color: #12427d!important;
  background: #fff;
}

.add-btn {
  background: #12427d;
}

.cancel-btn {
  background: #888;
}

.outer-tabs-wrapper {
    margin: 0.5rem;
    border: 1px solid #e0e0e0;
    padding: 5px 5px 10px;
    height: calc(100% - 30px);
    width: calc(100% - 25px);
}
