.status-approved {
  border: 1px solid #4cb944;
  background: #4cb944;
  color: #fff;
}

.status-in-progress {
  border: 1px solid #f1be39;
  background: #f1be39;
  color: #fff;
}

.status-pending {
  border: 1px solid #f1be39;
  background: #f1be39;
  color: #fff;
}

.status-registered {
  border: 1px solid #ccc;
  background: #fff;
  color: #fff;
}

.status-rejected {
  border: 1px solid #ff4d4d;
  background: #ff4d4d;
  color: #fff;
}

.status-draft {
  border: 1px solid #aaa;
  background: #aaa;
  color: #fff;
}
