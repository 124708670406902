.history-list-wrapper{
  height: 100%;
  overflow-y: hidden;

  .list-heading {
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    width: calc(100% - 25px);
  }

  ul.history-list { 
    list-style-type: none;
    padding: 0;
    font-size: 0.75rem;
    margin: 0;
    height: calc(100% - 75px);
    overflow-y: auto;
    margin:0;
    
    @media only screen and (max-width: 1280px) {
      font-size: 0.8rem;
    }


    li {
      .field-wrp{
        padding: 0 0 5px;
        display: flex;
        justify-content: start;
        
        label{
          margin-right: 2px;
          vertical-align: middle;
        }
        span{
          vertical-align: baseline;
        }
        span.value-wrapper *{
          margin: 0 2px 0 0;
        }
      }
      .date-field-wrp{
        justify-content: space-between;
      }
      h4 {
        margin: 5px 0;
        font-size: 0.8rem;
        font-weight: 500;
      }
      padding: 5px;
      border-bottom: 1px solid #ddd;
      .comments-list{
        list-style-type: none;
        padding: 0 10px;
        margin: 0;
        background: #f6f6f6;
        padding: 5px!important;
        border: 1px solid #ddd;
        border-radius: 4px;
        li.comment-item {
          border:0;
          margin: 0 0 10px 0;
          padding: 0;
          p {
            margin:-5px 0 0 0;
            background: none;
            border: 0;
            padding: 0;
          }
        }
      }
    }
    
  }
}

.alert-date {
  font-weight: 500;
  flex: 0 0 25%;
  max-width: 25%;
}
.aol-date {
  font-weight: 500;
  flex: 0 0 20%;
  max-width: 20%;
}
.value-wrapper{
  flex: 0 0 20%;
  font-weight: 500;
}
.assignee {
  flex: 0 0 25%;
  max-width: 25%;
  font-weight: 500;
}

.details-table {
  width: 100%;
  padding-right: 15px;
}

.details-row {
  display: flex;
  padding: 5px 0 0;
}

.date-col {
  width: 10%;
}

.limit-col {
  width: 7%;
}

.value-col {
  width: 7%;
}

.assignee-col {
  width: 14%;
}

.priority-col {
  width: 8%;
}

.severity-col {
  width: 8%;
}
